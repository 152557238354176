<swiper fxFlex="auto" [config]="config " (indexChange)="onIndexChange($event)"(window:resize)="onResize($event)">
  <div *ngFor="let slide of Slides; let index=index" class="swiper-slide cursor-pointer ">
    <div fxLayout="column " fxLayoutAlign="center center " fxFlexFill class="mx-2 ">
      <img src=" {{slide.url}} " alt=" ">
    </div>
  </div>
</swiper>

<div class="activeSlide">
  <ul>
    <li>{{Slides[0].text.name}}</li>
    <li>Автор: {{Slides[0].text.author}}</li>
    <li>Година: {{Slides[0].text.Year}}</li>
    <li>Формат: {{Slides[0].text.Format}}</li>
    <li>Страници: {{Slides[0].text.Pages}}</li>
    <span class="material-icons ">
      <a href="{{this.Slides[0].link}} " target="blank "></a>
    </span>
  </ul>
</div>
